import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import store, { RootState } from "../../../redux/store";
import { cartStatusMap } from "../../../models/cart";
import { AiOutlineLeft } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { DefaultResponse } from "../../../redux/mainSlice";
import order from "../../../models/order";
import { paymentUserStatus, paymentUserStatusMap } from "../../../models/payment";
import empty from "../../../assets/img/No data-pana-min.png";
import {
    IconArrowRight,
    IconBoxMultiple,
    IconCheckbox, IconChevronDown, IconCirclePlus,
    IconEye, IconFilter,
    IconMultiplier1x, IconSearch,
    IconX,
} from "@tabler/icons-react";
import {setPaginate, setPaginateToStart, setAllStatesToTrue} from "../../../redux/dashboard/orders/orderSlice";
import moment, {Moment} from "jalali-moment";
import {moneyFormat, pagination} from "../../../utils/funcions";
import {changePaymentStatus, getOrderCart} from "../../../redux/dashboard/orders/orderAction";
import DatePicker from 'react-datepicker2';
import 'moment/locale/fa';
import logo from "../../../assets/img/No data-pana-min.png";
import {getAllBuyed} from "../../../redux/dashboard/plans/buyedPlansAction";

const BUFFER_ZONE = 100;

function Orders() {
    const { ordersList, loading} = useAppSelector(
        (state: RootState) => state.order
    );
    const { shop, user} = useAppSelector(
        (state: RootState) => state.user
    );
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    interface values {
        statuses: string;
        id: any;
    }
    const [changeStatusNumber, setChangeStatusNumber] = useState(0);
    const changeStatusNewNumber = (numberSide: number) => {
        setChangeStatusNumber(numberSide);

    }

    // console.log('console.log', user.id, shop.id)

    const handleEditConfirm = async (values: values) => {
        const result: DefaultResponse = (await dispatch(changePaymentStatus(values))).payload as DefaultResponse;
        // return result;
    }

    const [title, setTitle] = useState<string>('');
    const [trackCode, setTrackCode] = useState<string>('');
    const lastSearchTimeRef = useRef(Date.now());

    const [mobilenumber, setMobile] = useState<string>('');
    const [minPrice, setMinPrice] = useState<string>('');
    const [maxPrice, setMaxPrice] = useState<string>('');
    const [status, setStatus] = useState<string>('');

    const [copy, setCopy] = useState<boolean>(true);

    const handleShowFilter = async () => {
        setCopy(!copy)
    };

    const [purchaseDate, setPurchaseDate] = useState<Moment | null>(null);
    const [purchaseEndDate, setPurchaseEndDate] = useState<Moment | null>(null);
    // const lastSearchTimeRef = useRef<number>(0);
    // const dispatch = useDispatch();

    const handleSearch = async () => {
        const currentTime = Date.now();
        dispatch(setPaginateToStart())
        dispatch(setAllStatesToTrue());
        if (currentTime - lastSearchTimeRef.current >= 2000) {
            let formattedDate: string | null = null;
            let formattedEndDate: string | null = null;

            if (purchaseDate) {
                formattedDate = purchaseDate.format('YYYY-MM-DD');
            }
            if (purchaseEndDate) {
                formattedEndDate = purchaseEndDate.format('YYYY-MM-DD');
            }

            const data = { title, trackCode, mobilenumber, minPrice, maxPrice, status, purchaseDate: formattedDate, purchaseEndDate: formattedEndDate };

            try {
                dispatch(getOrderCart(data));
            } catch (error) {
                alert('مشکلی پیش آمد.');
            }

            lastSearchTimeRef.current = currentTime; // Update the last search time
        } else {
            alert('2 ثانیه یکبار اقدام به جستجو کنید.');
        }
    };

    const handleDeleteSearch = () => {
        setTitle("");
    };
    const handleDeleteCode = () => {
        setTrackCode("");
    };

    const handleDeleteStartDate = () => {
        setPurchaseDate(null);
    };
    const handleDeleteEndDate = () => {
        setPurchaseEndDate(null);
    };

    const handleDeleteSearchByMobileNumber = () => {
        setMobile("");
    };
    const handleDeleteSearchByMinPayment = () => {
        setMinPrice("");
    };
    const handleDeleteSearchByMaxPayment = () => {
        setMaxPrice("");
    };
    // useEffect(() => {
    //     pagination(() => {
    //
    //         if (!store.getState().order.loadingMore && !store.getState().order.loading && !store.getState().order.isEnd) {
    //             dispatch(setPaginate());
    //             dispatch(getOrderCart(null));
    //         }})
    // });
    // useEffect(() => {
    //     setPaginateToStart();
    // }, []);
    //
    //

    useEffect(() => {
        let formattedDate: string | null = null;
        let formattedEndDate: string | null = null;

        if (purchaseDate) {
            formattedDate = purchaseDate.format('YYYY-MM-DD');
        }
        if (purchaseEndDate) {
            formattedEndDate = purchaseEndDate.format('YYYY-MM-DD');
        }

        const data = { title, trackCode, mobilenumber, minPrice, maxPrice, status, purchaseDate: formattedDate, purchaseEndDate: formattedEndDate };

        // const data = { fullname, mobilenumber, minPrice, maxPrice };

        dispatch(setPaginateToStart());
        dispatch(setAllStatesToTrue());
        const handlePagination = () => {
            if (!store.getState().order.loading &&
                !store.getState().order.loadingMore &&
                !store.getState().order.isEnd) {
                dispatch(setPaginate());
                dispatch(getOrderCart(data));
            }
        };

        const cleanup = pagination(handlePagination);
        return () => cleanup();
    }, [title, trackCode, mobilenumber, minPrice, maxPrice, status, purchaseDate, purchaseEndDate ]);

    // console.log('indexindex', ordersList)

    return (
        <div className="items-center w-full h-full lg:pl-28 md:pl-16 pl-3 md:pr-0 pr-3">
            <Link to={Routes.products.path}
                  className="cursor-pointer fixed top-0 z-[40] py-3 bg-white w-full md:hidden flex">
                <span><IconArrowRight/></span>
                <span className="mr-2">سفارش ها</span>
            </Link>
            <div
                className="py-4 pb-0 mb-2 mt-12 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">


                <div className={'flex w-full justify-between'}>
                    <span className={'flex items-center'}>سفارشات</span>
                    <div className={'flex justify-end'}>
                        <div onClick={() => handleShowFilter()}
                             className="btn flex w-max hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF] border-none p-3 rounded-xl text-white">
                            <span className='text-[15px]'>فیلتر ها</span>
                            {
                                <IconChevronDown className={`duration-300 ${copy ? 'rotate-0' : 'rotate-180'}`}/>
                            }
                        </div>
                    </div>
                </div>

            </div>

            <div className={'flex flex-col items-start w-full'}>
                <div
                    className={`w-full duration-300 mt-6 overflow-hidden ${copy ? 'h-0' : 'md:h-44 h-[446px]'} grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-3`}>
                    <label className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder=" جستجو براساس نام و نام خانوادگی "
                                value={title}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setTitle(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />
                            {title ? (
                                <button
                                    onClick={handleDeleteSearch}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>
                    <label className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder="کد پیگیری"
                                value={trackCode}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setTrackCode(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />
                            {trackCode ? (
                                <button
                                    onClick={handleDeleteCode}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>
                    <label className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="number"
                                placeholder="کمترین خرید"
                                value={minPrice}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setMinPrice(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />

                            {
                                minPrice ? <button
                                        onClick={handleDeleteSearchByMinPayment}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX size={'24'}/>
                                    </button> :
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size={'24'}/>
                                    </button>
                            }
                        </div>
                    </label>
                    <label className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="number"
                                placeholder="بیشترین خرید"
                                value={maxPrice}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setMaxPrice(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />

                            {
                                maxPrice ? <button
                                        onClick={handleDeleteSearchByMaxPayment}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX size={'24'}/>
                                    </button> :
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size={'24'}/>
                                    </button>
                            }
                        </div>
                    </label>

                    <label className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder="جستجو براساس شماره موبایل"
                                value={mobilenumber}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setMobile(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />
                            {mobilenumber ? (
                                <button
                                    onClick={handleDeleteSearchByMobileNumber}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>

                    <label className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                className="input placeholder:text-[14px] text-[14px] border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            >
                                <option value="" className={'text-[14px] font-iranyekan '}>جستجو براساس وضعیت</option>
                                {/*{products.map((e) => (*/}
                                <option value="posted">ارسال شده</option>
                                <option value="delivered">تحویل داده شده</option>
                                <option value="pending">درحال بررسی</option>
                                <option value="failed">لغو شده</option>
                                {/*))}*/}
                            </select>
                            <button
                                onClick={handleSearch}
                                className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                            >
                                <IconSearch size={'24'}/>
                            </button>
                        </div>
                    </label>
                    <label className="input input-bordered flex items-center p-1">
                        <div className="relative w-full z-[1000000000]">
                            {purchaseDate === null ? (
                                <input
                                    type="text"
                                    placeholder="تاریخ شروع"
                                    onFocus={() => setPurchaseDate(moment())} // or open DatePicker
                                    className="input placeholder:text-[13px] border-none pl-8 input-primary max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                                />
                            ) : (
                                <DatePicker
                                    isGregorian={false}
                                    value={purchaseDate}
                                    onChange={setPurchaseDate}
                                    timePicker={false}
                                    inputJalaaliFormat="jYYYY/jMM/jDD"
                                />
                            )}
                            {
                                purchaseDate ? <button
                                        onClick={handleDeleteStartDate}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX size="24"/>
                                    </button> :
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size="24"/>
                                    </button>
                            }
                        </div>
                    </label>
                    <label className="input input-bordered flex items-center p-1">
                        <div className="relative w-full z-[1000000000]">
                            {purchaseEndDate === null ? (
                                <input
                                    type="text"
                                    placeholder="تاریخ پایان"
                                    onFocus={() => setPurchaseEndDate(moment())} // or open DatePicker
                                    className="input placeholder:text-[13px] border-none pl-8 input-primary max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                                />
                            ) : (
                                <DatePicker
                                    isGregorian={false}
                                    value={purchaseEndDate}
                                    onChange={setPurchaseEndDate}
                                    timePicker={false}
                                    inputJalaaliFormat="jYYYY/jMM/jDD"
                                />
                            )}
                            {
                                purchaseEndDate ? <button
                                        onClick={handleDeleteEndDate}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX size="24"/>
                                    </button> :
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size="24"/>
                                    </button>
                            }
                        </div>
                    </label>

                </div>

                    <label className={`${copy ? 'h-0' : 'h-16'} flex items-center w-max`}>
                        <button onClick={handleSearch}
                                className={`w-full ${copy ? 'h-0 px-20' : 'py-3 h-12'} w-max px-12 group flex justify-center duration-300 rounded-lg hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF]`}>
                            <IconFilter
                                className={'group-hover:w-6 duration-300 w-0'}/>
                            <span className={`mr-2 ${copy ? 'duration-300 hidden' : 'block'}`}>اعمال فیلتر</span>

                        </button>
                    </label>
            </div>


            { (
                <div className="flex overflow-x-auto mt-[36px] flex-col items-center h-full w-full">
                    <div className={`p-0 overflow-x-auto ps w-full`}>
                        {(
                            <table
                                className="items-center w-full overflow-x-auto mb-0 align-top border-gray-200 text-slate-500"
                            >
                                <thead className="align-bottom">
                                <tr>
                                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام
                                        و نام خانوادگی
                                    </th>
                                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                        شماره موبایل
                                    </th>
                                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                        قیمت خرید
                                    </th>
                                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                        تاریخ خرید
                                    </th>
                                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                        کد پیگیری
                                    </th>
                                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">وضعیت
                                        ارسال
                                    </th>
                                    <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                        مشخصات
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {ordersList.map(order => order.map((customer, i) => (
                                    <tr
                                        key={customer.id}
                                        className={`transition-all mt-1 hover:bg-neutral-100 ${i % 2 === 1 ? "bg-[#4192EF15]" : "bg-white"} `}
                                    >
                                        <td className="px-6 py-3 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{customer.full_name}</td>

                                        <td className="px-6 py-3 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">
                                            {customer.mobile_number}
                                        </td>
                                        <td className="px-6 py-3 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">
                                            {moneyFormat(customer.final_price)}
                                        </td>

                                        <td className="px-6 py-3 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">
                                            {
                                                customer.created_at ?
                                                    moment(customer.created_at, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD') : ''}
                                        </td>
                                        {
                                            <td className="px-6 py-3 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-sm border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">
                                                {customer.tracking_code ? customer.tracking_code: '000000'}
                                            </td>
                                        }
                                        <td className={'px-6 py-4  pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80'}>
                                            {user!.id === shop!.id ? (
                                                <select
                                                    className="py-2.5 border cursor-pointer border-[#C7C7C7] bg-white rounded-lg px-2 w-46 min-w-[11rem] max-w-xs"
                                                    value={customer!.user_status}
                                                    onChange={(e) => handleEditConfirm({
                                                        statuses: e.target.value,
                                                        id: customer.id
                                                    })}
                                                >
                                                    <option style={{fontFamily: 'iranyekan'}} value="posted">ارسال شده
                                                    </option>
                                                    <option style={{fontFamily: 'iranyekan'}} value="delivered">تحویل
                                                        داده شده
                                                    </option>
                                                    <option style={{fontFamily: 'iranyekan'}} value="pending">درحال
                                                        بررسی
                                                    </option>
                                                    <option style={{fontFamily: 'iranyekan'}} value="failed">لغو شده
                                                    </option>
                                                </select>
                                            ) : (
                                                <span
                                                    className={'text-[14px]'}>{paymentUserStatusMap[customer!.user_status]}</span>
                                            )}
                                        </td>


                                        <td className="px-6 py-3 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">
                                            <Link to={Routes.order(customer.id).path}
                                                  className="p-2 bottom-0 hover:bg-[#4192EF] duration-300 bg-[#4192EF33] text-[#4192EF] hover:text-white rounded-lg flex font-bold text-center items-center capitalize align-middle shadow-none tracking-none whitespace-nowrap">
                                                <IconEye className={'ml-2'}/>
                                                مشاهده
                                            </Link>
                                        </td>
                                    </tr>
                                )))}
                                </tbody>
                            </table>

                        )}
                        {
                            loading && <div className={'main lg:pl-32 md:pl-72 mt-10 flex justify-center'}>
                                <svg className="ip" viewBox="0 0 256 128" width="256px" height="128px"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="0%" stop-color="#5555ee"/>
                                <stop offset="49%" stop-color="#4192EF"/>
                                <stop offset="70%" stop-color="#77DD77"/>
                                        </linearGradient>
                                        <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
                                            <stop offset="0%" stop-color="#5555ee"/>
                                            <stop offset="49%" stop-color="#4192EF"/>
                                            <stop offset="70%" stop-color="#77DD77"/>
                                        </linearGradient>
                                    </defs>
                                    <g fill="none" stroke={'#DDD'} stroke-linecap="round" stroke-width="16">
                                        <g className="ip__track" stroke="#AAA">
                                            <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                                            <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                                        </g>
                                        <g stroke-dasharray="180 656">
                                            <path className="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0"
                                                  d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                                            <path className="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358"
                                                  d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        }
                        {
                            ordersList.map(mainUsers => mainUsers.length === 0 ?
                                <div className={'w-full flex flex-col justify-center items-center'}>
                                    <img src={logo} alt="" className={'w-[30%]'}/>
                                    <span className={'text-lg'}> سفارشی وجود ندارد</span>
                                </div> : <div></div>
                            )
                        }
                        <div className="ps__rail-x">
                            <div className="ps__thumb-x"></div>
                        </div>
                        <div className="ps__rail-y">
                            <div className="ps__thumb-y"></div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Orders;

