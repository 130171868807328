import React, {useEffect, useRef, useState} from 'react';
import store, {RootState} from "redux/store";
import {useAppSelector} from "redux/hooks";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {
    IconArrowRight,
    IconChevronDown,
    IconCirclePlus, IconFilter,
    IconSearch,
    IconUser,
    IconX,
} from "@tabler/icons-react";
import {pagination} from "../../../utils/funcions";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {blockUserFromShop, getAllUsersForSingleMainPage} from "../../../redux/dashboard/users/UsersAction";
import {setPaginate, setPaginateToStart, setAllStatesToTrue} from "../../../redux/dashboard/users/UsersSlice";
import logo from 'assets/img/No data-pana-min.png'

const UsersList = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {mainUsersList, loading} = useAppSelector((state: RootState) => state.mainUsers);
    const [title, setTitle] = useState<string>('');
    const lastSearchTimeRef = useRef(Date.now());
    const [mobilenumber, setMobile] = useState<string>('');
    const [minPrice, setMinPrice] = useState<string>('');
    const [maxPrice, setMaxPrice] = useState<string>('');
    const [status, setStatus] = useState<string>('');

    const [copy, setCopy] = useState<boolean>(true);

    const handleShowFilter = async () => {
        setCopy(!copy)
    };


    const handleSearch = async () => {
        const currentTime = Date.now();
        dispatch(setPaginateToStart())
        dispatch(setAllStatesToTrue());
        if (currentTime - lastSearchTimeRef.current >= 2000) { // 2 seconds
            const data = { title, mobilenumber, minPrice, maxPrice, status };
            try {
                dispatch(getAllUsersForSingleMainPage(data));
            } catch (error) {
                alert('مشکلی پیش آمد.');
            }

            lastSearchTimeRef.current = currentTime; // Update the last search time
        } else {
            alert('2 ثانیه یکبار اقدام به جستجو کنید.');
        }
    };

    const handleDeleteSearch = () => {
        setTitle("");
    };

    const handleDeleteSearchByMobileNumber = () => {
        setMobile("");
    };
    const handleDeleteSearchByMinPayment = () => {
        setMinPrice("");
    };
    const handleDeleteSearchByMaxPayment = () => {
        setMaxPrice("");
    };



    useEffect(() => {
        const data = { title, mobilenumber, minPrice, maxPrice, status };

        dispatch(setPaginateToStart());
        dispatch(setAllStatesToTrue());
        const handlePagination = () => {
            if (!store.getState().mainUsers.loading &&
                !store.getState().mainUsers.loadingMore &&
                !store.getState().mainUsers.isEnd) {
                dispatch(setPaginate());
                dispatch(getAllUsersForSingleMainPage(data));
            }
        };

        const cleanup = pagination(handlePagination);
        return () => cleanup();
    }, [title, mobilenumber, minPrice, maxPrice, status]);

    const handleStatusClick = (id: number) => {
        dispatch(blockUserFromShop(id))
    };
    return (
        <div className={'lg:pl-28 md:pl-16 pl-3'}><Link to={Routes.products.path}
                                                        className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
            <span><IconArrowRight/></span>
            <span className={'mr-2'}>کاربران</span>
        </Link>
            <div
                className="py-4 pb-0 mb-2 mt-12 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">


                <div className={'flex w-full justify-between'}>
                    <span className={'flex items-center md:px-0 px-4'}>کاربران</span>
                    <div className={'flex justify-end'}>
                        <div onClick={() => handleShowFilter()}
                             className="btn flex w-max hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF] border-none p-3 rounded-xl text-white">
                            <span className='text-[15px]'>فیلتر ها</span>
                            {
                                <IconChevronDown className={`duration-300 ${copy ? 'rotate-0' : 'rotate-180'}`}/>
                            }
                        </div>
                    </div>
                </div>

            </div>

            <div className={'flex flex-col items-start w-full md:pr-0 pr-3'}>
                <div
                    className={`w-full duration-300 mt-6 overflow-hidden ${copy ? 'h-0' : 'md:h-32 h-72'} grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-3`}>
                    <label className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder=" جستجو براساس نام و نام خانوادگی "
                                value={title}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setTitle(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />
                            {title ? (
                                <button
                                    onClick={handleDeleteSearch}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>
                    <label className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="number"
                                placeholder="کمترین خرید"
                                value={minPrice}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setMinPrice(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />

                            {
                                minPrice ? <button
                                        onClick={handleDeleteSearchByMinPayment}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX size={'24'}/>
                                    </button> :
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size={'24'}/>
                                    </button>
                            }
                        </div>
                    </label>
                    <label className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="number"
                                placeholder="بیشترین خرید"
                                value={maxPrice}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setMaxPrice(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />

                            {
                                maxPrice ? <button
                                        onClick={handleDeleteSearchByMaxPayment}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX size={'24'}/>
                                    </button> :
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size={'24'}/>
                                    </button>
                            }
                        </div>
                    </label>

                    <label className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder="جستجو براساس شماره موبایل"
                                value={mobilenumber}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setMobile(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />
                            {mobilenumber ? (
                                <button
                                    onClick={handleDeleteSearchByMobileNumber}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>

                    <label className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            >
                                <option value="">جستجو براساس وضعیت</option>
                                {/*{products.map((e) => (*/}
                                <option value='true'>مسدود</option>
                                <option value='false'>آزاد</option>
                                {/*))}*/}
                            </select>
                            <button
                                onClick={handleSearch}
                                className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                            >
                                <IconSearch size={'24'}/>
                            </button>
                        </div>
                    </label>
                </div>

                <label className={`${copy ? 'h-0' : 'h-16'} flex items-center w-max`}>
                    <button onClick={handleSearch}
                            className={`w-full ${copy ? 'h-0 px-20' : 'py-3 h-12'} w-max px-12 group flex justify-center duration-300 rounded-lg hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF]`}>
                        <IconFilter
                            className={'group-hover:w-6 duration-300 w-0'}/>
                        <span className={`mr-2 ${copy ? 'duration-300 hidden' : 'block'}`}>اعمال فیلتر</span>

                    </button>
                </label>
            </div>

            {

                    <div className={'overflow-x-auto'}>

                        <table
                            className="items-center w-full mb-0 mt-[36px] align-top border-gray-200 text-slate-500">
                            <thead className="align-bottom">
                            <tr>
                                {/*<th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">#</th>*/}
                                <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام
                                    و نام خانوادگی
                                </th>
                                <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">شماره
                                    موبایل
                                </th>
                                <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">خرید
                                    ها
                                </th>
                                <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">وضعیت</th>
                            </tr>

                            </thead>
                            {(
                                <tbody>

                                {mainUsersList.map(mainUsers => mainUsers.map((formQuestion, i) => (
                                    <tr key={formQuestion.id.toString()}
                                        className={`transition-all mt-1 hover:bg-neutral-100 ${i % 2 === 1 ? "bg-[#4192EF15]" : "bg-white"}`}>
                                        {/*<td className="px-6 py-4 pl-2 font-bold text-start align-middle  bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{i + 1}</td>*/}
                                        <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{formQuestion.f_name} {formQuestion.l_name}</td>
                                        <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{formQuestion.mobile_number}</td>
                                        <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{formQuestion.payments_count}</td>
                                        {/*{formQuestion.id}*/}
                                        <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">
                                            <div onClick={() =>
                                                handleStatusClick(formQuestion.id)
                                            }
                                                 className={`inline-block duration-300 px-4 py-3 mb-0 font-bold text-center uppercase align-middle transition-all border-0 rounded-lg shadow-none cursor-pointer leading-pro text-xs ease-soft-in hover:scale-102 active:opacity-85 bg-x-25  ${formQuestion.is_blocked == true ? "hover:bg-[#4191EF] bg-[#4191EF33] hover:text-white text-[#4191EF] px-[25.6px]" : "hover:bg-[#DE0046] bg-[#DE004633] hover:text-white text-[#DE0046]"}  `}
                                            >
                                                {formQuestion.is_blocked == true ?
                                                    'آزاد کردن'
                                                    :
                                                    'مسدود کردن'
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )))}

                                </tbody>
                            )}
                        </table>
                        {
                            loading && !mainUsersList && <div className={'main lg:pl-32 md:pl-72 mt-10 flex justify-center'}>
                                <svg className="ip" viewBox="0 0 256 128" width="256px" height="128px"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="0%" stop-color="#5555ee"/>
                                <stop offset="49%" stop-color="#4192EF"/>
                                <stop offset="70%" stop-color="#77DD77"/>
                                        </linearGradient>
                                        <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
                                <stop offset="0%" stop-color="#5555ee"/>
                                <stop offset="49%" stop-color="#4192EF"/>
                                <stop offset="70%" stop-color="#77DD77"/>
                                        </linearGradient>
                                    </defs>
                                    <g fill="none" stroke={'#DDD'} stroke-linecap="round" stroke-width="16">
                                        <g className="ip__track" stroke="#AAA">
                                            <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                                            <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                                        </g>
                                        <g stroke-dasharray="180 656">
                                            <path className="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0"
                                                  d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                                            <path className="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358"
                                                  d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        }
                        {
                            mainUsersList.every(e => e.length == 0) && !loading ?
                                <div className={'w-full flex flex-col justify-center items-center'}>
                                    <img src={logo} alt="" className={'w-[30%]'}/>
                                    <span className={'text-lg'}>کاربری وجود ندارد</span>
                                </div> : <div></div>
                        }
                        <div className="ps__rail-x">
                            <div className="ps__thumb-x"></div>
                        </div>
                        <div className="ps__rail-y">
                            <div className="ps__thumb-y"></div>
                        </div>
                    </div>

            }

        </div>
    );
};

export default UsersList;