import {createSlice, current} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import User from "../../../models/user";
import {LaravelValidationErrorType} from "../../../utils/types";
import {getReplacementAvatar, toastSuccess} from "../../../utils/funcions";
import {getUserInfo, updateProfile} from "redux/user/user/userAction";
import {Shop} from "../../../models/shop";
import {replacementUserAvatar} from "../../../utils/constants";
import Setting, {SettingInterface} from "../../../models/setting";
import SettingField from "../../../models/setting_field";
import store from "../../store";
import {setIsServerOnline} from "../../tools/checkOnline/checkOnlineSlice";
import ShopPlan from "../../../models/shopPlan";

interface State extends DefaultStates {
    user?: User;
    users?: User[];
    shop?: Shop;
    plan?: ShopPlan;
    storage_full?: boolean;
    storage?: string;
    is_expired?: boolean|null;
    settings: Setting[];
    products?: number;
    category?: number;
    usersCount?: number;
    orders?: number;
    plan_id?: number;
    settingFields: SettingField[];
    settingFamilies: {
        local_family: string,
        plan_id: number,
        family: string,
    }[];
    submitLoading: boolean;
    isMerchantFilled: boolean,
    isHaveReseller: boolean,
    isHaveReferral: boolean,
}

const initialState: State = {
    user: {
        is_blocked: false,
        user_type: 'user',
        id: 0,
        username: 'ورود / ثبت نام',
        avatar: replacementUserAvatar,
        banner: replacementUserAvatar,
        description: 'میهمان',
        fName: 'کاربر',
        lName: 'میهمان',
        mobileNumber: 'میهمان',
        email: 'میهمان',
        video: 'میهمان',
        walletAmount: 'میهمان',
        isReseller: false,
        payments: [],
        f_name: 'میهمان',
        l_name: 'میهمان',
    },
    shop: {
        id: 0,
        description: 'فروشگاه',
        avatar: replacementUserAvatar,
        banner: replacementUserAvatar,
        video: 'فروشگاه',
        username: 'فروشگاه',
    },
    is_expired: false,
    category: undefined,
    orders: undefined,
    usersCount: undefined,
    storage: undefined,
    plan: undefined,
    products: undefined,
    submitLoading: false,
    plan_id: undefined,
    loading: true,
    validationErrors: null,
    settingFields: [],
    settings: [],
    users: [],
    settingFamilies: [],
    isMerchantFilled: false,
    isHaveReferral: false,
    isHaveReseller: false,
};

const UsersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        //update avatar
        updateAvatar: (state, action) => {
            state.user = {...state.user!, avatar: action.payload};
            if (state.user!.id === state.shop!.id) {
                state.shop = {...state.shop!, avatar: action.payload};
            }
        },
        updateSettings: (state, action) => {
            // state.settingField = (response.data['settings_fields']).map(($s: SettingFieldInterface) => new SettingField($s));
            const settingFields: SettingField[] = [];
            Object.entries(action.payload['settings_fields']).forEach(([key, settingField]) => {
                settingFields.push(new SettingField({...(settingField as any), ...{name: key}}));
            });
            state.settingFields = settingFields;
            state.settings = action.payload['settings'].map(($s: SettingInterface) => new Setting($s));
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateProfile.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            state.submitLoading = false;
            // console.log('consol,.log')
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                state.user = new User(response.data);
                if (state.user.id === state.shop!.id) {
                    state.shop = new Shop(response.data);
                }
                toastSuccess('پروفایل با موفقیت بروزرسانی شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(updateProfile.rejected, (state, action) => {
            state.submitLoading = false;
        })
        builder.addCase(getUserInfo.pending, (state) => {
            // if (state.loading == false) {
            state.loading = true
            // }
        })
        builder.addCase(getUserInfo.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                state.user = response.data['user'] ? new User(response.data['user']) : undefined;
                state.is_expired = response.data['is_expired'] ;
                state.shop = new Shop(response.data['shop']);
                state.storage_full = response.data['storage_full'];
                state.storage = response.data['storage'];
                state.plan = response.data['plan'];
                state.products = response.data['products'];
                state.category = response.data['category'];
                state.usersCount = response.data['usersCount'];
                state.orders = response.data['orders'];
                // state.settingField = (response.data['settings_fields']).map(($s: SettingFieldInterface) => new SettingField($s));
                state.settingFamilies = response.data['setting_families'];
                state.settingFields = [];
                if (response.data['settings_fields']) {
                    Object.entries(response.data['settings_fields']).forEach(([key, settingField]) => {
                        state.settingFields.push(new SettingField({...(settingField as any), ...{name: key}}));
                    });
                }
                if (state.settings)
                    state.settings = response.data['settings'].map(($s: SettingInterface) => new Setting($s));
                if (!state.user) {
                    state.user = {
                        is_blocked: false,
                        user_type: 'user',
                        id: 0,
                        username: 'ورود / ثبت نام',
                        avatar: replacementUserAvatar,
                        banner: replacementUserAvatar,
                        description: 'میهمان',
                        fName: 'کاربر',
                        lName: 'میهمان',
                        mobileNumber: 'میهمان',
                        email: 'میهمان',
                        video: 'میهمان',
                        walletAmount: 'میهمان',
                        isReseller: false,
                        payments: [],
                        f_name: 'میهمان',
                        l_name: 'میهمان',
                    };
                }
                state.isMerchantFilled = response.data['is_merchant_filled'];
                state.plan_id = response.data['plan_id'];
                state.isHaveReseller = response.data['is_reseller_active'];
                state.isHaveReferral = response.data['is_referral_active'];
            }
            if (response.status === 401 || response.status === 403) {
                window.location.reload();
            }
            else if (response.status === 408) {
                state.user = response.data
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
            else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getUserInfo.rejected, (state, action) => {
            state.loading = false;
            store.dispatch(setIsServerOnline(false));
        })

    },
});

export default UsersSlice.reducer;
export const {updateAvatar, updateSettings} = UsersSlice.actions;