import React, {useEffect} from 'react';
import {BrowserRouter, Routes, useLocation} from "react-router-dom";
import 'App.css';
import 'index.css';
import RootComponents from "components/root/rootComponents";
import Index from "./pages/front";
import {useAppSelector} from "./redux/hooks";
import store, {RootState} from "./redux/store";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {getUserInfo} from "./redux/user/user/userAction";
import {checkOnline} from "./redux/tools/checkOnline/checkOnlineAction";
import {AnalyticsMainPage} from "./pages/front/analytics/AnalyticsMainPage";
import Store from "./redux/store";
import localToken from "./services/savedData/localToken";
import logo from 'assets/img/coloop-logo-44-min.-copy.png'
import {WarehousingMainPage} from "./pages/front/warehousing/main";
function App() {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    //check
    async function requestUserInfo() {
        dispatch(getUserInfo({isReturnCache: true}));
        dispatch(getUserInfo({isReturnCache: false}));
    }
    //check if url start with /analytics
    const isAnalytics = window.location.pathname.startsWith('/analytics');
    const isWarehousing = window.location.pathname.startsWith('/warehouse');
    React.useEffect(() => {
        if (!localToken.get()) {
            localToken.set(Math.floor(1000000000 + Math.random() * 9000000000).toString());
        }
        dispatch(checkOnline());
        requestUserInfo();
    } ,[]);
    const {loading, isInternetOnline, isServerOnline, status} = useAppSelector((state: RootState) => state.checkOnline);
    const {storage_full} = useAppSelector((state: RootState) => state.user);

    const user = useAppSelector((state: RootState) => state.user);
    // const location = useLocation();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location]);

    return (
        storage_full ? <div className="w-full h-full flex justify-center items-center overflow-x-hidden">
            <div className="text-center">
                <h1 className="text-2xl text-red-500">
                    فضای ذخیره سازی فروشگاه تمام شده
                </h1>
                <p>
                    فضای ذخیره سازی فروشگاه پر شده است لطفا پلن فروشگاه را ارتقاء دهید
                </p>
                <a href="https://coloop.ir" className="btn outline-none text-white bg-[#4192EF]">
                    خرید پلن
                </a>
            </div>
        </div> :
            status === 405 ? <div className="w-full h-full flex justify-center items-center overflow-x-hidden">
            <div className="text-center">
                <h1 className="text-2xl text-red-500">
                    فروشگاه یافت نشد
                </h1>
                <p>
                    فروشگاه مورد نظر یافت نشد برای ثبت فروشگاه به آدرس زیر مراجعه کنید
                </p>
                <a href="https://coloop.ir" className="btn btn-primary">
                    ثبت فروشگاه
                </a>
            </div>
        </div> :
            !isInternetOnline ? <div className="w-full h-full flex justify-center items-center overflow-x-hidden">
            <div className="text-center">
                <h1 className="text-2xl text-red-500">
                    شما آفلاین هستید
                </h1>
                <p>اتصال اینترنت خود را بررسی کنید</p>

            </div>
        </div> : !isServerOnline ? <div className="w-full h-full flex justify-center items-center overflow-x-hidden">
            <div className="text-center">
                <h1 className="text-2xl text-red-500">
                    سرور آفلاین است
                </h1>
                <p>
                    سرور برای انجام تعمیرات آفلاین است
                </p>
                <p>
                    کمی دیگر مجددا آنلاین خواهیم شد ...
                </p>
            </div>
        </div> : user.loading ? <div className="w-full h-[100vh] flex justify-center items-center overflow-x-hidden">
                {
                    loading && <div className={'main lg:pl-32 md:pl-72 mt-10 flex justify-center'}>
                        <svg className="ip" viewBox="0 0 256 128" width="256px" height="128px"
                             xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
                                    <stop offset="0%" stop-color="#5ebd3e"/>
                                    <stop offset="33%" stop-color="#ffb900"/>
                                    <stop offset="67%" stop-color="#f78200"/>
                                    <stop offset="100%" stop-color="#e23838"/>
                                </linearGradient>
                                <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
                                    <stop offset="0%" stop-color="#e23838"/>
                                    <stop offset="33%" stop-color="#973999"/>
                                    <stop offset="67%" stop-color="#009cdf"/>
                                    <stop offset="100%" stop-color="#5ebd3e"/>
                                </linearGradient>
                            </defs>
                            <g fill="none" stroke={'#DDD'} stroke-linecap="round" stroke-width="16">
                                <g className="ip__track" stroke="#AAA">
                                    <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                                    <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                                </g>
                                <g stroke-dasharray="180 656">
                                    <path className="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0"
                                          d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                                    <path className="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358"
                                          d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                }


            </div> : isAnalytics ? <BrowserRouter
                    //@ts-ignore
                    forceRefresh={true}>
                    <AnalyticsMainPage/>
                </BrowserRouter>
                : isWarehousing ? <BrowserRouter
                    //@ts-ignore
                    forceRefresh={true}>
                    <WarehousingMainPage/>
                </BrowserRouter> : <div className="w-full mx-auto md:pb-0">
                    <BrowserRouter
                        //@ts-ignore
                        forceRefresh={true}>
                        <Index/>
                        <RootComponents/>
                    </BrowserRouter>
                </div>
    );
}

export default App;
