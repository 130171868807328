import React, {useEffect, useState} from 'react';
import moment from "jalali-moment";
import {useAppSelector} from "redux/hooks";
import {RootState} from "redux/store";
import Routes from "../../../utils/routing/routes";
import {IconArrowRight, IconMessage, IconTruck} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import {
    getAllNotifications,
} from "../../../redux/dashboard/notifications/unreadNotificationsAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
// import notification from "../../../models/notification";
import logo from "../../../assets/img/empty-notification-illustration-download-in-svg-png-gif-file-formats--state-no-states-pack-user-interface-illustrations-8593294.png";
// import notification from "../../../models/notification";

const NotificationsList = () => {
    const {notifications, loading} = useAppSelector((state: RootState) => state.unreadNotificationsSlice);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    useEffect(() => {
        dispatch(getAllNotifications());

    }, [dispatch]);

    console.log(
        // 'conole.log', notifications
    )

    return (
        <div className="flex-auto px-0 pt-0 pb-2">
            <Link to={Routes.products.path}
                  className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>اعلان ها</span>
            </Link>
            <div
                className="py-4 pb-0 mb-2 mt-14 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">


                <div className={'flex w-full justify-between'}>
                    <span className={'flex items-center md:px-0 px-4'}>اعلان ها</span>
                </div>
            </div>
            <div className="flex flex-col px-4 w-full gap-y-5 mt-20">
                {
                    loading ? '' :
                   notifications.length === 0 && !loading ?
                       <div className={'w-full flex flex-col justify-center items-center'}>
                           <img src={logo} alt="" className={'md:w-[30%] w-[50%]'}/>
                           <span className={'text-lg'}>اعلانی وجود ندارد</span>
                       </div> :  notifications.map((e) => <div className={`flex gap-x-3 items-start p-2 rounded-xl duration-300 ${e.title === 'تیکتی برای شما ارسال شده' ? 'hover:bg-[#4192EF33]' : 'hover:bg-[#5ce65c33] '}`}>
                        <div
                            className={`'flex rounded-full p-2 ${e.title === 'تیکتی برای شما ارسال شده' ? 'bg-[#4192EF33] text-[#4192EF]' : 'bg-[#5ce65c33] text-[#5ce65c]'} `}>
                            {
                                e.title === 'تیکتی برای شما ارسال شده' ? <IconMessage size={28}/> :
                                    <IconTruck size={28}/>

                            }
                        </div>
                        <div className={'flex flex-col gap-y-1'}>
                            <span className={'text-[13px]'}>{e.title}</span>
                            <span className={'text-[14px]'}>{e.message?.slice(0, 99)}</span>
                            {/*<span className={'text-[12px]'}> {e.created_at && moment(e.created_at).locale('fa').format('HH:mm')} {moment(e.created_at, 'YYYY/MM/DD/HH/mm').locale('fa').format('YYYY/MM/DD')}</span>*/}
                        </div>
                    </div>)
                }
            </div>
        </div>
    );
};

export default NotificationsList;
