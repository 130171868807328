import React, {useEffect, useState} from 'react';
import {useAppSelector} from "redux/hooks";
import {RootState} from "redux/store";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {remove, update} from 'redux/dashboard/categories/categoriesAction';
import {DeleteModal} from "../../../components/modal";
import Category from "../../../models/category";
import {DefaultResponse} from "../../../redux/mainSlice";
import EditCategoryModal from './edit';
import { Link } from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {emptyComponent, loadingComponent, NetworkImage} from "../../../utils/funcions";
import dLoad from "../../../assets/img/cantLoadImage.webp";
import {MdOutlineMoreHoriz} from "react-icons/md";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import logo from "../../../assets/img/No data-pana-min.png";

const CategoriesList = () => {

    const {categories, loading} = useAppSelector((state: RootState) => state.adminCategories);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Category | null>(null);
    const [editingItem, setEditingItem] = useState<Category | null>(null);
    const [deletedId, setDeletedId] = useState<number>(0);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const handleDeleteClick = (category: Category) => {
        setSelectedItem(category);
        setIsModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            setDeletedId(selectedItem.id);
            setSelectedItem(null);
            setIsModalOpen(false);
            const result: DefaultResponse = (await dispatch(remove(selectedItem.id))).payload as DefaultResponse;
            if (result.status !== 200 && result.status !== 201) {
                setDeletedId(0);
            }
        }
    };
    const {shop, user} = useAppSelector((state: RootState) => state.user);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="flex-auto pt-0 pb-16 mt-[65px]">
            <DeleteModal
                title="حذف دسته بندی"
                message="آیا از حذف این دسته بندی مطمئن هستید؟"
                onSubmit={handleDeleteConfirm}
                isOpen={isModalOpen}
                handleIsOpen={setIsModalOpen}
            ></DeleteModal>

            <EditCategoryModal
                category={editingItem}
                onClose={(isOpen: boolean) => {
                    setEditingItem(null);
                }}
            ></EditCategoryModal>

            <div className={'w-full flex flex-col pb-4'}>
                <div className={'flex w-full flex-col justify-start'}>
                    <div className={'w-full'}>
                        {
                            loading ?  <div className={'main lg:pl-32 md:pl-72 mt-10 flex justify-center'}>
                                <svg className="ip" viewBox="0 0 256 128" width="256px" height="128px"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="0%" stop-color="#5555ee"/>
                                <stop offset="49%" stop-color="#4192EF"/>
                                <stop offset="70%" stop-color="#77DD77"/>
                                        </linearGradient>
                                        <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
                                <stop offset="0%" stop-color="#5555ee"/>
                                <stop offset="49%" stop-color="#4192EF"/>
                                <stop offset="70%" stop-color="#77DD77"/>
                                        </linearGradient>
                                    </defs>
                                    <g fill="none" stroke={'#DDD'} stroke-linecap="round" stroke-width="16">
                                        <g className="ip__track" stroke="#AAA">
                                            <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                                            <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                                        </g>
                                        <g stroke-dasharray="180 656">
                                            <path className="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0"
                                                  d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                                            <path className="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358"
                                                  d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                         : categories.length == 0 ?

                                    <div className={'w-full flex flex-col justify-center items-center'}>
                                        <img src={logo} alt="" className={'md:w-[30%] w-1/2'}/>
                                        <span className={'text-lg'}>دسته بندی وجود ندارد</span>
                                    </div>
                             : categories.map((category, i) =>
                                <div>
                                    {
                                        <div className={'flex flex-col'}>

                                            <div className={'flex flex-col w-full mt-4'}>
                                                <div className={'flex w-full justify-between'}>
                                                <span
                                                    className={'text-[12px] font-bold'}>{(category.products == null || category.products.length == 0) && user!.id !== shop!.id ?
                                                    <div></div>
                                                    : <div className={'flex items-center'}>
                                                        <div
                                                            className="flex cursor-pointer transition-all duration-300">

                                                            {user!.id === shop!.id && (
                                                                <div className="rounded-lg ml-4 w-4 h-4">
                                                                    <div className="dropdown dropdown-right rounded-lg">
                                                                        <label tabIndex={0}
                                                                               className="bg-transparent cursor-pointer ">
                                                                            <MdOutlineMoreHoriz size={24}
                                                                                                color="gray   "/>
                                                                        </label>
                                                                        <ul tabIndex={0}
                                                                            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-44">
                                                                            <li
                                                                                onClick={() => {
                                                                                    setEditingItem(category);
                                                                                }}
                                                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                                <div
                                                                                    className="px-1 bg-transparent hover:bg-transparent">
                                                                                    <AiOutlineEdit
                                                                                        onClick={() => {
                                                                                            setEditingItem(category);
                                                                                        }
                                                                                        }
                                                                                        size={24}/>
                                                                                    ویرایش
                                                                                </div>
                                                                            </li>
                                                                            <li

                                                                                onClick={() => {
                                                                                    handleDeleteClick(category);
                                                                                }}
                                                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                                <div
                                                                                    className="px-1 text-[#DE0046] bg-transparent hover:bg-transparent">
                                                                                    <AiOutlineDelete size={24}/>
                                                                                    حذف
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>)}
                                                        </div>
                                                        <div>{category.title}</div>
                                                    </div>
                                                }

                                                </span>
                                                    {
                                                        category.products == null || category.products.length == 0 ?
                                                            <div></div>
                                                            : <div className={'flex items-start'}>
                                                                <Link to={
                                                                    Routes.categoriesProductsList(category.id).path}
                                                                      className={'text-[12px] text-[#4192EF]'}>مشاهده
                                                                    همه</Link>
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     className="icon text-[#4192EF] mb-1 mr-2 font-bold icon-tabler icon-tabler-chevron-down"
                                                                     width="22" height="22" viewBox="0 0 24 24"
                                                                     strokeWidth="1.5" stroke="currentColor"
                                                                     fill="none"
                                                                     strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z"
                                                                          fill="none"/>
                                                                    <path d="M6 9l6 6l6 -6"/>
                                                                </svg>
                                                            </div>
                                                    }
                                                </div>
                                                <div className={'flex mt-[11px] carousel '}>

                                                    {
                                                        <div className={'w-full'}>
                                                            {


                                                                <div className={'flex'}>
                                                                    {
                                                                        <div className={'flex'}>
                                                                            {category.products == null ?
                                                                                <div></div> : <div>
                                                                                    <div className={'md:flex-row md:flex  w-full hidden'}>
                                                                                        {
                                                                                            category.products!.slice(0, 18).map((product, index) => (
                                                                                                <Link
                                                                                                    to={Routes.product(product.id).path}
                                                                                                    key={index}
                                                                                                    className={'mr-1 w-[80px] carousel-item h-[80px] rounded-lg bg-white'}
                                                                                                >
                                                                                                    <div
                                                                                                        className={'w-[80px] carousel-item h-[80px] bg-white rounded-lg ml-[8px]'}>
                                                                                                        {/*{e.title}*/}
                                                                                                        {/*<NetworkImage url={user!.avatar} alt={''}/>*/}
                                                                                                        {
                                                                                                            product.media.length == 0 ?
                                                                                                                <img
                                                                                                                    className="w-[80px] h-[80px] rounded-lg bg-white object-cover text-[12px] justify-center items-center flex"
                                                                                                                    src={dLoad}
                                                                                                                    alt={""}/>
                                                                                                                :
                                                                                                                <NetworkImage
                                                                                                                    className="w-[80px] h-[80px] object-cover text-[12px] justify-center items-center flex"
                                                                                                                    url={product.media[0].thumbnail}
                                                                                                                    alt={product.title}/>
                                                                                                        }
                                                                                                        {/*<span className={'text-[#DE0046]'}>{e!.title}</span>*/}
                                                                                                    </div>
                                                                                                </Link>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                    <div className={'md:hidden flex w-full flex-row'}>
                                                                                        {
                                                                                            category.products!.slice(0, 6).map((product, index) => (
                                                                                                <Link
                                                                                                    to={Routes.product(product.id).path}
                                                                                                    key={index}
                                                                                                    className={'mr-1 w-[80px] carousel-item h-[80px] rounded-lg bg-white'}
                                                                                                >
                                                                                                    <div
                                                                                                        className={'w-[80px] carousel-item h-[80px] bg-white rounded-lg ml-[8px]'}>
                                                                                                        {/*{e.title}*/}
                                                                                                        {/*<NetworkImage url={user!.avatar} alt={''}/>*/}
                                                                                                        {
                                                                                                            product.media.length == 0 ?
                                                                                                                <img
                                                                                                                    className="w-[80px] h-[80px] rounded-lg bg-white object-cover text-[12px] justify-center items-center flex"
                                                                                                                    src={dLoad}
                                                                                                                    alt={""}/>
                                                                                                                :
                                                                                                                <NetworkImage
                                                                                                                    className="w-[80px] h-[80px] object-cover text-[12px] justify-center items-center flex"
                                                                                                                    url={product.media[0].thumbnail}
                                                                                                                    alt={product.title}/>
                                                                                                        }
                                                                                                        {/*<span className={'text-[#DE0046]'}>{e!.title}</span>*/}
                                                                                                    </div>
                                                                                                </Link>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {category.products == null ?
                                                                                <div></div> :
                                                                                <div>
                                                                                    <div className={'md:flex hidden'}>
                                                                                        {

                                                                                            category.products!.length > 18 &&
                                                                                            <div
                                                                                                className={'flex mr-1'}>
                                                                                                <Link
                                                                                                    to={Routes.categoriesProductsList(category.id).path}
                                                                                                    className={'w-[80px] flex justify-center items-center carousel-item h-[80px] bg-[#00000022] text-[12px] text-black'}>
                                                                                                    {(category.products_count!) - 18}  محصول دیگر
                                                                                                </Link>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div className={'md:hidden flex'}>
                                                                                        {

                                                                                            category.products!.length > 6 &&
                                                                                            <div
                                                                                                className={'flex mr-1'}>
                                                                                                <Link
                                                                                                    to={Routes.categoriesProductsList(category.id).path}
                                                                                                    className={'w-[80px] flex justify-center items-center carousel-item h-[80px] bg-[#00000022] text-[12px] text-black'}>
                                                                                                    {(category.products_count!) - 6} محصول دیگر
                                                                                                </Link>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>


                                                                            }
                                                                        </div>
                                                                    }

                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                </div>
                                                {
                                                    category.children ? category.children.map((product, i) =>
                                                        <div className={'mr-6 mt-4'}>
                                                                <div className={'flex w-full justify-between'}>
                                                                <span
                                                                    className={'text-[12px] font-bold'}>{(product.products == null || product.products.length == 0) && user!.id !== shop!.id ?
                                                    <div></div>
                                                    : <div className={'flex items-center'}>
                                                        <div
                                                            className="flex cursor-pointer transition-all duration-300">

                                                            {user!.id === shop!.id && (
                                                                <div className="rounded-lg ml-4 w-4 h-4">
                                                                    <div className="dropdown dropdown-right rounded-lg">
                                                                        <label tabIndex={0}
                                                                               className="bg-transparent cursor-pointer ">
                                                                            <MdOutlineMoreHoriz size={24}
                                                                                                color="gray   "/>
                                                                        </label>
                                                                        <ul tabIndex={0}
                                                                            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-44">
                                                                            <li
                                                                                onClick={() => {
                                                                                    setEditingItem(product);
                                                                                }}
                                                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                                <div
                                                                                    className="px-1 bg-transparent hover:bg-transparent">
                                                                                    <AiOutlineEdit
                                                                                        onClick={() => {
                                                                                            setEditingItem(product);
                                                                                        }
                                                                                        }
                                                                                        size={24}/>
                                                                                    ویرایش
                                                                                </div>
                                                                            </li>
                                                                            <li

                                                                                onClick={() => {
                                                                                    handleDeleteClick(product);
                                                                                }}
                                                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                                <div
                                                                                    className="px-1 text-[#DE0046] bg-transparent hover:bg-transparent">
                                                                                    <AiOutlineDelete size={24}/>
                                                                                    حذف
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>)}
                                                        </div>
                                                        <div>{product.title}</div>
                                                    </div>
                                                }

                                                </span>
                                                                    {
                                                                        product.products == null || product.products.length == 0 ?
                                                                            <div></div>
                                                                            : <div className={'flex items-start'}>
                                                                                <Link to={
                                                                                    Routes.categoriesProductsList(product.id).path}
                                                                                      className={'text-[12px] text-[#4192EF]'}>مشاهده
                                                                                    همه</Link>
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                     className="icon text-[#4192EF] mb-1 mr-2 font-bold icon-tabler icon-tabler-chevron-down"
                                                                                     width="22" height="22" viewBox="0 0 24 24"
                                                                                     strokeWidth="1.5" stroke="currentColor"
                                                                                     fill="none"
                                                                                     strokeLinecap="round"
                                                                                     strokeLinejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z"
                                                                                          fill="none"/>
                                                                                    <path d="M6 9l6 6l6 -6"/>
                                                                                </svg>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div className={'flex mt-[11px] carousel '}>

                                                                    {
                                                                        <div className={'w-full'}>
                                                                            {


                                                                                <div className={'flex'}>
                                                                                    {
                                                                                        <div className={'flex'}>
                                                                                            {product.products == null ?
                                                                                                <div></div> : product.products!.slice(0, 6).map((e, index) => (
                                                                                                    <Link
                                                                                                        to={Routes.product(e.id).path}
                                                                                                        key={index}
                                                                                                        className={'mr-1 w-[80px] carousel-item h-[80px] bg-white'}
                                                                                                    >
                                                                                                        <div
                                                                                                            className={'w-[80px] carousel-item h-[80px] bg-white ml-[8px]'}>
                                                                                                            {/*{e.title}*/}
                                                                                                            {/*<NetworkImage url={user!.avatar} alt={''}/>*/}
                                                                                                            {
                                                                                                                e.media.length == 0 ?
                                                                                                                    <img
                                                                                                                        className="w-[80px] h-[80px] bg-white object-cover text-[12px] justify-center items-center flex"
                                                                                                                        src={dLoad}
                                                                                                                        alt={""}/>

                                                                                                                    // <img
                                                                                                                    //     className="w-[80px] h-[80px] bg-white object-cover text-[12px] justify-center items-center flex"
                                                                                                                    //     src={dLoad}
                                                                                                                    //     alt={product.title}/>
                                                                                                                    :
                                                                                                                    <NetworkImage
                                                                                                                        className="w-[80px] h-[80px] object-cover text-[12px] justify-center items-center flex"
                                                                                                                        url={e.media[0].thumbnail}
                                                                                                                        alt={e.title}/>
                                                                                                            }
                                                                                                            {/*<span className={'text-[#DE0046]'}>{e!.title}</span>*/}
                                                                                                        </div>
                                                                                                    </Link>
                                                                                                ))
                                                                                            }
                                                                                            {product.products == null ?
                                                                                                <div></div> : product.products!.length > 6 &&
                                                                                                <div
                                                                                                    className={'flex mr-1'}>
                                                                                                    <Link
                                                                                                        to={Routes.categoriesProductsList(product.id).path}
                                                                                                        className={'w-[80px] flex justify-center items-center carousel-item h-[80px] bg-[#00000022] text-[12px] text-black'}>
                                                                                                        {category.products!.length} محصول
                                                                                                    </Link>
                                                                                                </div>


                                                                                            }
                                                                                        </div>
                                                                                    }

                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                    ) : <div></div>
                                                }
                                            </div>

                                        </div>
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
        ;

};

export default CategoriesList;
