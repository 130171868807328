import React from 'react';
import { DefaultInput, DefaultSelect } from "../../../components/fieilds";
import { Form, Formik, FormikHelpers } from "formik";
import DefaultModal from "../../../components/modal";
import { submit } from "../../../redux/dashboard/categories/categoriesAction";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {IconArrowRight} from "@tabler/icons-react";


interface Values {
    title: string;
    parentId?: number;
}

const AddCategoryModal = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { submitLoading, categories } = useAppSelector((state: RootState) => state.adminCategories);

    return (
        <DefaultModal
            label={'addCategory'} id='addCategory' modalBoxClasses={'px-3'}>
            <Formik
                initialValues={{
                    title: '',
                    parentId: undefined,
                }}
                //@ts-ignore
                onSubmit={async (values: Values, { resetForm, setSubmitting }: FormikHelpers<Values>) => {
                    try {
                        await dispatch(submit(values));
                        (document.querySelector("[for='addCategory']") as HTMLElement).click();
                        resetForm();
                    } catch (error) {
                        console.error(error);
                    } finally {
                        // Set submitting to false after form submission
                        setSubmitting(false);
                    }
                }}
                // Validation function
                validate={(values: Values) => {
                    const errors: any = {};
                    if (!values.title) {
                        errors.title = 'نام محصول را وارد کنید';
                    }
                    /*if (values.parentId) {
                        if (!categories.find((category) => category.id === values.parentId)) {
                            errors.parentId = 'دسته بندی والد معتبر نیست';
                        }
                    }*/
                    return errors;
                }}
            >
                <Form method="dialog">
                    <label htmlFor={'addProduct'}
                           className={'cursor-pointer fixed top-0 z-[40]  px-2 py-3 bg-white w-full md:hidden flex'}>
                        <span><IconArrowRight/></span>
                        <span className={'mr-2'}>افزودن</span>
                    </label>
                    <div className='flex-col justify-start items-center w-full flex md:mt-4 mt-12'>
                        <DefaultInput
                            label='نام دسته بندی'
                            name="title"
                            type="text"
                            placeholder='نام دسته بندی'
                        />
                        <DefaultSelect
                            label='انتخاب کنید'
                            name="parentId"
                            placeholder='انتخاب کنید'
                            options={categories.map((category) => ({
                                label: category.title,
                                value: category.id
                            })) as any}
                        />
                    </div>
                    <div className="modal-action w-full flex justify-start">
                        <button
                            type="submit"
                            className={`btn text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}
                            disabled={submitLoading}
                        >
                            {submitLoading ? 'صبر کنید' : 'افزودن'}
                        </button>
                        <div className={'w-0.5 h-2'}></div>
                        <label
                            htmlFor="addCategory"
                            className="btn text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85 bg-[#DE0046]">بستن
                        </label>
                    </div>
                </Form>
            </Formik>
        </DefaultModal>
    );
};

export default AddCategoryModal;
