import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {ChartDataInterface} from "./AnalyticsDashboard";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {parseNumber} from "react-advanced-cropper";
import {SiGravatar} from "react-icons/si";
import {ChartModalData} from "./chartModalData";
import {loadingComponent} from "../../../utils/funcions";

export const ProductsAnalyticsPage: React.FC<{}> = () => {
    const {data, loading} = useAppSelector((state: RootState) => state.analytics);
    const {openSideBar, show} = useAppSelector((state: RootState) => state.analyticsSidebarSlice);

    return (
        loading ? loadingComponent() : <div className={`py-7 flex w-full gap-7 transition-all duration-1000 ease-in-out ${
            openSideBar
                ? "sm:max-w-[calc(100vw_-_229px)] lg:max-w-[calc(100vw_-_286px)] "
                : "sm:max-w-[calc(100vw_-_99px)] lg:max-w-[calc(100vw_-_110px)] xl:max-w-[calc(100vw_-_138px)]"
        }`}>
            <div className="flex flex-col w-full py-10">
                <ChartModalData data={
                    {
                        name: "محصولات برتر در میزان فروش هر ماه",
                        data: data.mostProductSoldAmountOf30DaysAgo.map((data) => parseNumber(data.product_amount)),
                        icon: SiGravatar,
                        color: "#7851BD",
                        labels: data.mostProductSoldAmountOf30DaysAgo.map((data, i) => data.product_name ? data.product_name + ` (${i+1})` : `${i+1}`),
                    }
                }/>
            {/*    for count*/}
                <ChartModalData data={
                    {
                        name: "محصولات برتر در تعداد فروش هر ماه",
                        data: data.mostProductSoldCountOf30DaysAgo.map((data) => parseNumber(data.product_count)),
                        icon: SiGravatar,
                        color: "#7851BD",
                        labels: data.mostProductSoldCountOf30DaysAgo.map((data, i) => data.product_name ? data.product_name + ` (${i+1})` : `${i+1}`),
                    }
                }/>
            </div>
        </div>
    );
}